* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Mr Eaves Mod OT Reg', sans-serif;
}

.preload{
    justify-content: center;
    align-items: center;
    height: 200px;
    display: flex;
    width:100%;
  height: auto;
}

.home,
.aboutUs,
.contact, .impress {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.aboutUs {
  background-image: url('/images/Team.JPG');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  background-image: url('/images/Kontakt.jpeg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.content{
  background-color: white;
}
.sectionpic1{
  background-image: url('/images/Fernsteuerung.jpg');
  width: 100%;
  height: 500px;
  background-size: cover;
  display: flex;
}
.impresspic1{
  background-image: url('/images/DrohneBox.jpg');
  width: 100%;
  height: 500px;
  background-size: cover;
}
.sectionpic2{
  background-image: url('/images/Luftaufnahme.jpg');
  width: 100%;
  height: 500px;
  background-size: cover;
}
.sectionpic3{
  background-image: url('/images/Maeharbeit.jpg');
  width: 100%;
  height: 500px;
  background-size: cover;
}
.sectionpic4{
  background-image: url('/images/DrohneImFlug.jpg') ;
  width: 100%;
  height: 500px;
  background-size: cover;
}
.contenttyp1{
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;

  hyphens: auto; 
	text-align: justify
}
.contenttyp2{
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}
.contentelements{
  position: relative;
  margin: 40px 0 45px;

}
.contentItem{
  display: flex;
  flex: 1;
  float:left;
}
.contentItem2{
  display: flex;
  flex: 1;
  float:right;
}
.contentpic{
  height: 550px;
  border-radius: 10px;
}
.contentpic:hover {
  transform: scale(1.005);
}

.contentpic2{
  height: 550px;
  border-radius: 10px;
  margin-left: 700px;
}
.contenttext
{
  padding-bottom: 20px;
  font-size: 20px;
}
.contenttext2
{
  padding-bottom: 20px;
  font-size: 20px;
}

.welcometext{
  font-size: 20px;
}
.maintext{
  justify-content: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}
.impresstext{
  padding-bottom: 20px;
  font-size: 20px;
}
.blackBambi{
  color: black;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.impresstext a{
  text-decoration: none;
  color: black;
}
.impresstext a:hover{
  text-decoration: underline;
}
#maintop
{
  height: 50px;
}
